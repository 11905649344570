import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";

import "bootstrap/dist/css/bootstrap.css"; // add for bootstrap
import "bootstrap-vue/dist/bootstrap-vue.css"; // add for bootstrap
import VueApexCharts from "vue3-apexcharts";

createApp(App).use(store, key).use(router).use(VueApexCharts).mount("#app");
