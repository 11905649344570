import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

export interface UserData {
  name: string;
  weight0: number;
  weight1: number;
  weight2: number;
  weight3: number;
  satisfy0: number;
  satisfy1: number;
  satisfy2: number;
  satisfy3: number;
  score: number;
}

// ストアの型を定義する
export interface State {
  userdata: UserData;
}

// InjectionKeyを定義する
export const key: InjectionKey<Store<State>> = Symbol();

// Storeを作成する
export const store = createStore<State>({
  state: {
    userdata: {
      name: "",
      weight0: 25,
      weight1: 25,
      weight2: 25,
      weight3: 25,
      satisfy0: 50,
      satisfy1: 50,
      satisfy2: 50,
      satisfy3: 50,
      score: 0,
    },
  },
  /*
  getters: {
    getFirstBook: (state) => {
      return state.books ? state.books[0] : {} as Book
    }
  },
  actions: {
    add ({ commit, state }, book: Book) {
      commit('add', { book: book })
    }
  },
  mutations: {
    add (state, { book }) {
      state.books.push(book)
    }
  }
  */
});

// 独自のuserStoreメソッドを定義する
export function useStore(): Store<State> {
  // InjectionKeyをuserStoreメソッドに渡す
  return baseUseStore(key);
}
